export const TRANSLATE = {
  ko: {
    googleFormURL:
      'https://docs.google.com/forms/d/e/1FAIpQLSdD_cCHRVEPI2GvatxHS-ec0GoNLzpRF_eZxAZyiatMuzio8Q/viewform',
    introductionDriveURL:
      'https://drive.google.com/file/d/19nYMZTfBnpxIfHyHF5y_-PjA53uPIoSK/view',
  },
  en: {
    googleFormURL:
      'https://docs.google.com/forms/d/186NHXrOzw07a-H9L8vtZeqrYMbre5VIK2hrVHa2JYr4/viewform?edit_requested=true',
    introductionDriveURL:
      'https://drive.google.com/file/d/1Us_8lphtXmA7Li2KyzKBO1dpOwqdbs20/view',
  },
} as const;

export const LANGUAGE_LIST = ['ko', 'en'];
