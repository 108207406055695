import { Global } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import React from 'react';
import tw, { css } from 'twin.macro';
import logo from 'core/assets/icon/logo.svg';
import { LANGUAGE_LIST, TRANSLATE } from 'core/util/translate';
import GA from 'core/view/common';

export const googleFormUrl = (lang?: string): string => {
  const l = lang as keyof typeof TRANSLATE;
  if (!TRANSLATE[l]) return TRANSLATE.ko.googleFormURL;
  return TRANSLATE[l].googleFormURL;
};

const activeA = css`
  ${tw`text-[#111]`};

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 3px;
    background: #111111;
    border-radius: 10px;
  }
`;

const Gnb = (): ReactElement => {
  const router = useRouter();
  const { t, i18n } = useTranslation('home');
  const lang = i18n.resolvedLanguage;

  const onToggleLanguageClick = (newLocale: string) => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };

  const title =
    (process.env.NEXT_PUBLIC_ENV_NODE_TEXT || '') +
    '한국스토리지협회(Self Storage Association Korea) ';
  const desc = `한국스토리지협회는 국내 셀프스토리지 산업 발전에 기여하고 공동의 성장을 위해 노력합니다. 국내에 새로운 보관 문화가 정착하는데 앞장서겠습니다.`;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url !== router.pathname) {
        setIsOpen(false);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      setIsOpen(false);
    };
  }, [router]);

  return (
    <>
      <style global jsx>{`
        html {
          min-width: 340px;
          overflow: scroll;
        }
      `}</style>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={desc} />
        <meta property="og:description" content={desc} />

        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_CLOUD_FRONT}/partners/ssak/og.jpg`}
        />
        <meta
          name="keywords"
          content={`개인스토리지
					셀프스토리지가격
					셀프스토리지비용
					세대창고
					개인창고
					미니창고
					개인창고가격
					공유창고
					셀프스토리지창업
					공유창고창업
					미니스토리지
					공유스토리지
					스토리지룸
					공유창고협회
					협회
					공유창고사업자
					공유창고모임
					개인창고모임
					개인창고협회
					스토리지협회
					공유창고단체
					개인창고단체
					스토리지단체`.replace(/[\n\s\t]+/g, ',')}
        />

        <GA.SSAK />
      </Head>

      <nav
        css={[
          tw`sticky z-[1] top-0`,
          tw`h-[80px]`,
          tw`bg-white box-shadow[0px 4px 4px rgba(0, 0, 0, 0.15)]`,
          tw`flex items-center justify-between gap-[28px]`,
          tw`pr-[24px] lg:pr-[30px] break1280:pr-[40px]`,
          tw`pl-[20px] md:pl-[30px]`,
        ]}
      >
        <Link href="/" passHref={true}>
          <a aria-label="한국스토리지협회 메인으로 이동">
            <img src={logo.src} alt="" width={131} height={46} />
          </a>
        </Link>
        <article css={tw`x_768:(block) hidden`}>
          <button onClick={() => setIsOpen(true)}>
            <IconHamburger />
            <span tw="sr-only">메뉴열기</span>
          </button>
        </article>
        <ul
          css={[
            tw`x_768:(hidden) flex`,
            tw`gap-[28px]`,
            tw`font-bold text-16 leading-24`,
            tw`text-transform[uppercase]`,
            tw`ml-auto lg:ml-[initial]`,
          ]}
        >
          {[
            { text: 'Association', href: '/association' },
            { text: 'News', href: '/news' },
            { text: 'Membership', href: '/membership' },
          ].map(({ text, href }, i) => {
            return (
              <li key={i}>
                <Link href={`/${href}`.replace('//', '/')} passHref={true}>
                  <a
                    css={[
                      tw`relative py-[7px] cursor-pointer`,
                      href === router.pathname ? activeA : tw`text-[#666]`,
                    ]}
                  >
                    {text}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>

        <div css={[tw`flex gap-x-14`, tw`x_768:(hidden)`]}>
          <a
            href={googleFormUrl(lang)}
            target={'_blank'}
            rel="noreferrer"
            css={[
              tw`px-24 pb-8 pt-7`,
              tw`border[1px solid #111] rounded-full`,
              tw`text-14 leading-20  font-bold text-[#111]`,
            ]}
          >
            {t('gnb.join')}
          </a>

          <Language onToggleLanguageClick={onToggleLanguageClick} />
        </div>
      </nav>

      {isOpen && (
        <article
          css={[
            tw`w-[100%] h-[100%]`,
            tw`p-[22px 24px 40px]`,
            tw`bg-white`,
            tw`x_768:(fixed top-0 left-0 z-[1])`,
            tw`x_768:(flex flex-col) hidden`,
          ]}
        >
          <div css={[tw`text-right`]}>
            <button onClick={() => setIsOpen(false)}>
              <IconClose />
              <span tw="sr-only">메뉴닫기</span>
            </button>
          </div>
          <article
            css={[tw`flex flex-col items-center justify-center`, tw`flex-1`]}
          >
            <Language onToggleLanguageClick={onToggleLanguageClick} />
            <div css={tw`h-28`}></div>
            <ul
              css={[
                tw`flex flex-col gap-18`,
                tw`text-transform[uppercase]`,
                tw`font-bold text-center text-26 leading-39`,
                tw`[& > li]:(h-62 flex items-center justify-center)`,
              ]}
            >
              {[
                { text: 'Home', href: '/' },
                { text: 'Association', href: '/association' },
                { text: 'News', href: '/news' },
                { text: 'Membership', href: '/membership' },
              ].map(({ text, href }, i) => {
                return (
                  <li key={i}>
                    <Link href={`/${href}`.replace('//', '/')} passHref={true}>
                      <a
                        css={[
                          tw`relative cursor-pointer`,
                          href === router.pathname ? activeA : tw`text-[#666]`,
                        ]}
                      >
                        {text}
                      </a>
                    </Link>
                  </li>
                );
              })}
              <li css={[tw`pt-10`, tw`opacity-40`]}>
                <a
                  href={googleFormUrl(lang)}
                  target={'_blank'}
                  rel="noreferrer"
                  css={[
                    tw`box-content rounded-full  border[1px solid #111]`,
                    tw`px-24 pt-7 pb-9`,
                    tw`font-bold text-20 leading-30 text-[#111]`,
                  ]}
                >
                  {t('gnb.join')}
                </a>
              </li>
            </ul>
          </article>
          <div>
            <p css={[tw`text-[#111] opacity-40`, tw`mx-auto text-center`]}>
              © Copyright Self Storage Association Korea
              <br />
              All Rights Reserved.
            </p>
          </div>
        </article>
      )}
      {isOpen && (
        <Global
          styles={css`
            html {
              ${tw`x_768:(overflow-hidden) overflow[initial]`}
            }
          `}
        />
      )}
    </>
  );
};

export default Gnb;

const IconHamburger = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10C8 9.44772 8.44772 9 9 9H27C27.5523 9 28 9.44772 28 10V11C28 11.5523 27.5523 12 27 12H9C8.44772 12 8 11.5523 8 11V10ZM8 17C8 16.4477 8.44772 16 9 16H27C27.5523 16 28 16.4477 28 17V18C28 18.5523 27.5523 19 27 19H9C8.44772 19 8 18.5523 8 18V17ZM9 23C8.44772 23 8 23.4477 8 24V25C8 25.5523 8.44772 26 9 26H27C27.5523 26 28 25.5523 28 25V24C28 23.4477 27.5523 23 27 23H9Z"
        fill="#333333"
      />
    </svg>
  );
};

const IconClose = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54703 24.849C9.1565 25.2395 9.1565 25.8727 9.54703 26.2632L10.2541 26.9703C10.6447 27.3608 11.2778 27.3608 11.6683 26.9703L18.4225 20.2162L25.4078 26.7301C25.8117 27.1067 26.4445 27.0846 26.8211 26.6807L27.5031 25.9494C27.8798 25.5454 27.8577 24.9127 27.4538 24.536L20.5451 18.0936L27.2247 11.414C27.6152 11.0234 27.6152 10.3903 27.2247 9.99976L26.5176 9.29265C26.1271 8.90212 25.4939 8.90212 25.1034 9.29265L18.3497 16.0463L11.364 9.53205C10.9601 9.15539 10.3273 9.17749 9.95065 9.5814L9.26865 10.3128C8.89199 10.7167 8.91409 11.3495 9.31801 11.7261L16.2271 18.1689L9.54703 24.849Z"
        fill="#666666"
      />
    </svg>
  );
};

const Language = ({
  onToggleLanguageClick,
}: {
  onToggleLanguageClick: (newLocale: string) => void;
}) => {
  const { i18n } = useTranslation('home');
  const lang = i18n.resolvedLanguage;

  return (
    <div
      css={tw`relative flex items-center gap-x-15 text-16 x_768:(text-26 font-semibold gap-x-24)`}
    >
      {LANGUAGE_LIST.map((l, idx, arr) => {
        const isNotLast = idx !== arr.length - 1;
        const isSelected = lang === l;

        return (
          <React.Fragment key={`${l}-button-${idx}`}>
            <button
              type="button"
              onClick={() => onToggleLanguageClick(l)}
              css={[isSelected ? tw`text-[#333] cursor-text` : tw`text-[#999]`]}
            >
              {l?.toUpperCase()}
            </button>
            {isNotLast && (
              <span
                css={[tw`absolute right-25 text-[#999]`, tw`x_768:(right-42)`]}
              >
                /
              </span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
