const SSAK = () => {
  if (process.env.NEXT_PUBLIC_ENV_NODE !== 'production') {
    return <></>;
  }

  const GOOGLE_ANALYTICS = 'UA-256159271-2';

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GOOGLE_ANALYTICS}');
`,
        }}
      />
    </>
  );
};

const common = {
  SSAK,
};

export default common;
