import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import tw from 'twin.macro';
import footerlogo from 'core/assets/icon/footerlogo.svg';

const BaseLogo = () => {
  return (
    <img src={footerlogo.src} alt="" width={134} height={44} loading="lazy" />
  );
};

const Footer = (): JSX.Element => {
  const { t } = useTranslation('home');
  return (
    <footer
      css={[
        tw`font-OpenSans`,
        tw`bg-[#444444]`,
        tw`pt-[24px] md:pt-[38px]`,
        tw`pl-[24px] md:pl-[80px]`,
        tw`pb-[38px] md:pb-[54px]`,
      ]}
    >
      <div
        css={[
          tw`max-w-[1280px]`,
          tw`break1680:grid break1680:grid-cols-[minmax(auto, 175px) 1fr auto] gap-[38px] md:gap-[38px] break1680:gap-[66px]`,
          tw`mx-auto`,
          tw`flex flex-col`,
        ]}
      >
        <article>
          <BaseLogo />
        </article>

        <article
          css={[
            tw`order-2 break1680:order-none`,
            tw`line-height[1.5em] break1680:line-height[2em]`,
            tw`text-[13px] font-weight[600] text-[#eee]`,
            tw`flex flex-col gap-[24px] break1680:gap-[18px]`,
          ]}
        >
          <p css={tw`text-white`}>
            <span css={tw`inline-block`}>
              {'© Copyright Self Storage Association Korea |'}&nbsp;
            </span>
            <span css={tw`inline-block`}>All Rights Reserved.</span>
          </p>

          <div css={[tw`flex flex-col gap-[24px] break1680:gap-[2px]`]}>
            <div css={[tw`break1680:flex gap-[10px]`]}>
              <div>
                <span css={[tw`text-[13px] font-weight[600] mr-[15px] `]}>
                  {'E. '}
                  <a
                    href="mailto:hello@selfstoragekorea.org"
                    css={[tw`text-[14px] font-weight[400] hover:(opacity-70)`]}
                  >
                    hello@selfstoragekorea.org
                  </a>
                </span>
                <span css={tw`font-weight[600] no-underline`}>
                  {'T. '}
                  <span css={tw`font-weight[400] no-underline`}>
                    02-6925-5455
                  </span>
                </span>
              </div>
              <div css={[tw`text-[14px] font-weight[400]`]}>
                {t('footer.address')}
              </div>
            </div>
          </div>

          <article>
            <Link href="/policy" passHref>
              <a target="_blank" rel="noreferrer">
                {t('footer.privatePolicy')}
              </a>
            </Link>
          </article>
        </article>

        <article css={[tw`order-1 break1680:order-none`]}>
          <ul
            css={[
              tw`flex break1680:flex-col gap-[30px] break1680:gap-[10px]`,
              tw`[>li]:(text-[14px] font-bold text-[#fff])`,
              tw`text-transform[uppercase]`,
            ]}
          >
            <li>
              <Link href="/association">Association</Link>
            </li>
            <li>
              <Link href="/news">News</Link>
            </li>
            <li>
              <Link href="membership">Membership</Link>
            </li>
          </ul>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
