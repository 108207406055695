// customs
import { GetStaticProps } from 'next';
import { SSRConfig, appWithTranslation } from 'next-i18next';
import i18nextConfig from 'next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { GlobalStyles } from 'twin.macro';
import 'core/style/globals.scss';
import Footer from 'core/view/Footer';
import Gnb from 'core/view/Gnb';

type Props = {
  pageProps: any;
  Component: any;
  router: any;
};

function MyApp({ Component, pageProps }: Props): ReactElement {
  const router = useRouter();
  const noGNB = ['/policy'].includes(router.pathname);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5"
        />
      </Head>
      <GlobalStyles />
      {!noGNB && <Gnb />}
      <Component {...pageProps} />
      {!noGNB && <Footer />}
    </>
  );
}

export default appWithTranslation(MyApp);

export const getStaticProps: GetStaticProps<SSRConfig> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(
        locale as string,
        undefined,
        i18nextConfig,
      )),
    },
  };
};
